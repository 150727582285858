import './testimonials.scss'
import { Testimonial } from '../testimonial/Testimonial'
const Testimonials = () => {
    const testimonials = [
        {
            id: 1,
            testimonialText: "Forge your destiny as a Warrior, mastering the sword in fierce battles while also gathering resources and crafting powerful gear to enhance your strength!",
            img: "./images/warrior.png",
            name: "Warrior",
        },
        {
            id: 2,
            testimonialText: "Step into the shadows as a Rogue, using your bow to outmaneuver foes and gather rare materials, crafting unique items that showcase your cunning and skill!",
            img: "./images/rogue.png",
            name: "Rogue",
        },
        {
            id: 3,
            testimonialText: "Unlock the secrets of the arcane as a Mage, wielding your magic staff to cast spells and gather mystical ingredients, crafting potent potions and enchanted artifacts to aid your journey!",
            img: "./images/mage.png",
            name: "Mage",
        }
    ]
  return (
    <div className='testimonials'>
        {testimonials.map(t =><Testimonial key={t.id} testimonial ={t} />)}
    </div>
  )
}

export default Testimonials;
