import './header.scss';
import React, { useState } from 'react';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <div className="header">
      <span>Ageless Republic</span>
      <nav className={`menu ${isMenuOpen ? 'active' : ''}`}>
        <button className="hamburger" aria-label="Toggle Menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <ul className="menu-list">
          <li onClick={() => {setIsMenuOpen(false)}}>Home</li>
          <li onClick={() => {setIsMenuOpen(false)}}>Leaderboard</li>
          <li onClick={() => {setIsMenuOpen(false)}}>Game Items</li>
          <li onClick={() => {setIsMenuOpen(false)}}>Game Skills</li>
        </ul>
      </nav>
    </div>
  )
}
