import "./intro.scss";

const Intro = () => {
  return (
    <div className="intro">
      <div className="wrapper">
        <img src="./images/model2.webp" alt="intro illustration" />
        <div className="explain">
          <h1>Explore, Craft, and Conquer!</h1>
          <a href="https://agelessrepublic.com/downloads/ageless_republic.apk" download>Download Game</a>
        </div>
      </div>
    </div>
  );
};

export default Intro;